/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Transition Properties"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for transition properties, use the ", _jsx(_components.code, {
        children: "theme.transitionProperties"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n\n  export const theme = {\n    transitionProperties: {\n      // ...\n      colors: ['background-color', 'border-color', 'color', 'fill', 'stroke'],\n      opacity: ['opacity'],\n      shadow: ['box-shadow'],\n      transform: ['transform'],\n+     dialogs: ['box-shadow', 'transform'],\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "transitionProperties"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  transitionProperties: {\n    default: [\n      'background-color',\n      'border-color',\n      'color',\n      'fill',\n      'stroke',\n      'opacity',\n      'box-shadow',\n      'transform',\n    ],\n    colors: ['background-color', 'border-color', 'color', 'fill', 'stroke'],\n    opacity: ['opacity'],\n    shadow: ['box-shadow'],\n    transform: ['transform'],\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
